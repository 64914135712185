import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { CallConversationModel, CallDebriefModel, CallEvaluationModel, CallTranscriptionModel, useCallService } from '../services/call.service';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiFileInput } from 'mui-file-input'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
const CallSupervisor = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const steps = [
        {
            "label": "Fichier audio",
            "subtext": "(mp3, .wav)"
        },
        {
            "label": "Evaluation de l'appel",
            "subtext": "Avec score global"
        },
        {
            "label": "Debriefing",
            "subtext": ""
        }
    ];

    const [selectedAudioFile, setSelectedAudioFile] = React.useState<any | undefined>(undefined);
    const [transcriptionResult, setTranscriptionResult] = React.useState<any | undefined>(undefined);
    const [conversationResult, setConversationResult] = React.useState<any | undefined>(undefined);
    const [evaluationResult, setEvaluationResult] = React.useState<any | undefined>(undefined);
    const [debriefResult, setDebriefResult] = React.useState<string | undefined>(undefined);



    const [errorMessage, setErrorMessage] = React.useState(null);

    const {
        evaluateCall,
        getConversationCall,
        transcribeFile,
        generateDebrief,
        isTranscribeFileLoading,
        isEvaluateCallLoading,
        isGetConversationCallLoading,
        isGenerateDebriefLoading } = useCallService();
    const isStepOptional = (step: number) => {
        return false;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleGetConversation = async () => {
        try {
            if (!transcriptionResult) throw new Error("Transcription result is not available yet.");
            setErrorMessage(null);
            const datas: CallConversationModel = {
                "content": transcriptionResult
            }
            const res = await getConversationCall(datas);
            setConversationResult(res);

        } catch (err: any) {
            setErrorMessage(err.data ? err.data.detail : err.message);
        }
    };
    const handleTranscribeFile = async () => {
        try {
            if (!selectedAudioFile) throw new Error("Selected audio is not available yet.");;;
            setErrorMessage(null);
            const formData = new FormData();
            formData.append("file", selectedAudioFile);
            const res = await transcribeFile(formData);
            setTranscriptionResult(res.content);

        } catch (err: any) {
            setErrorMessage(err.data ? err.data.detail : err.message);
        }
    };

    const handleEvaluateCall = async () => {
        if (!selectedAudioFile) throw new Error("Please select an audio file");
        setErrorMessage(null);
        let datas = new FormData();
        datas.append("file", selectedAudioFile)
        const res = await evaluateCall(datas);
        console.log('Manoella', res)
        //Manoella
        setEvaluationResult(res);
    };

    const handleGenerateDebrief = async () => {
        try {
            if (!selectedAudioFile) throw new Error("Please select an audio file");
            setErrorMessage(null);
            let datas = new FormData();
            datas.append("file", selectedAudioFile)
            const res = await generateDebrief(datas);
            console.log('Manoella', res)
            setDebriefResult(res);

        } catch (err: any) {
            setErrorMessage(err.data ? err.data.detail : err.message);
        }
    };
    const handleNext = async () => {
        try {
            if (activeStep === 0 && !evaluationResult) await handleEvaluateCall();
            if (activeStep === 1 && !debriefResult) await handleGenerateDebrief();




            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        } catch (err: any) {
            setErrorMessage(err.data ? err.data.detail : err.message);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setSelectedAudioFile(undefined);
        setTranscriptionResult(undefined);
        setEvaluationResult(undefined);
        setConversationResult(undefined);
        setDebriefResult(undefined);

        setActiveStep(0);
    };


    React.useEffect(() => {
        if (!selectedAudioFile) return;
        setTranscriptionResult(undefined);
        setEvaluationResult(undefined);
        setConversationResult(undefined);
        setDebriefResult(undefined);


    }, [selectedAudioFile]);



    const canPassToNextStep = React.useCallback((): boolean => {
        return !isTranscribeFileLoading && !isGetConversationCallLoading && !isEvaluateCallLoading && !isGenerateDebriefLoading; // if no function is loading
    }, [isTranscribeFileLoading, isGetConversationCallLoading, isEvaluateCallLoading, isGenerateDebriefLoading]);

    
    const handleSelectedAudioFileChange = (newValue : any) => {
        setSelectedAudioFile(newValue)
        console.log(newValue);
      }
    return (
        <div style={{ width: "100%", display: "flex", alignItems: "start", justifyContent: "center", paddingTop: "100px", paddingBottom: "100px" }}>
            <div style={{ textAlign: 'left', maxWidth: '1000px' }}>
                <h1 style={{ fontSize: 75, color: "#015b97", marginBottom: 15 }}>Superviseur d'appel</h1>
                <p style={{ fontSize: 40, color: "#777777", marginTop: "0px", fontWeight: "500" }}>Un appel à superviser ? Je suis là pour vous aider.</p>

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((step, index) => {
                            const label = step.label;

                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            labelProps.optional = (
                                <Typography variant="caption">{step.subtext}</Typography>
                            );
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                Toutes les étapes sont terminées
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Réinitialiser</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div style={{ minHeight: 400, paddingTop: 20 }}>


                                {
                                    activeStep === 0 &&
                                    <>
                                        <Typography sx={{ mt: 2, mb: 1 }}><b>Etape n°{activeStep + 1}</b> : Choisir l'enregistrement audio à traiter</Typography>
                                        <Box sx={{ minWidth: 120 }}>
                                            <MuiFileInput placeholder="hey" disabled={!canPassToNextStep()} label="Enregistrement audio" style={{width:"100%"}} value={selectedAudioFile} onChange={handleSelectedAudioFileChange} />
                                        </Box>
                                        {errorMessage && <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>{errorMessage}</Alert>}
                                    </>

                                }
                                {
                                    activeStep === 1 &&
                                    <>
                                        <Typography sx={{ mt: 2, mb: 1 }}><b>Etape n°{activeStep + 1}</b> : Résultat de l'évaluation de l'appel </Typography>
                                        {errorMessage && <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>{errorMessage}</Alert>}
                                        {/*<Typography style={{marginBottom:20}}>Note de l'appel : <b style={{color : "#015b97"}}>{evaluationResult ? evaluationResult.score : "-"} point(s) / {evaluationResult ? evaluationResult.max_score : "-"}</b></Typography>*/}
                                        {evaluationResult && evaluationResult.result.map((rubrique: any, rubriqueItem: number) => {
                                            /*return rubrique.items.map((item : any)=>{
                                                <div>{rubrique.label} : {item.label}</div>
                                            });*/
                                            //return <div>{rubrique.label}</div>;
                                            return (
                                                <Accordion key={`item-${rubriqueItem}`}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`panel${rubriqueItem}-content`}
                                                        id={`panel${rubriqueItem}-header`}
                                                    >
                                                        <b>{rubrique.label}</b>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {
                                                            rubrique.items.map((item: any, itemIndex: number) => {
                                                                return <div key={`item-${rubriqueItem}-${itemIndex}`} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 5, paddingBottom: 5 }}>
                                                                    <div>{item.label} : <b>{item.assessment} </b></div>

                                                                    <Tooltip title={
                                                                        <ul style={{ fontSize: 16, paddingLeft: 20 }}>
                                                                            <li><b>Avis :</b>{item.notice}</li>
                                                                            <li><b>Extraits de texte :</b>{item.portion_of_text.join(", ")}</li>
                                                                        </ul>
                                                                    }>
                                                                        <IconButton>
                                                                            <InfoIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            })
                                                        }
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}

                                    </>

                                }
                                {
                                    activeStep === 2 &&
                                    <div className='debrief'>
                                        <Typography sx={{ mt: 2, mb: 1 }}><b>Etape n°{activeStep + 1}</b> : Debriefing </Typography>
                                        {errorMessage && <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>{errorMessage}</Alert>}
                                        {
                                            debriefResult && <Markdown remarkPlugins={[remarkGfm]}>{debriefResult}</Markdown>
                                        }
                                    </div>

                                }
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Etape précédente
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {isStepOptional(activeStep) && (
                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                        Passer
                                    </Button>
                                )}
                                <LoadingButton
                                    onClick={handleNext}
                                    loading={!canPassToNextStep()}
                                    disabled={!selectedAudioFile || !canPassToNextStep()}
                                >
                                    <span>{activeStep === steps.length - 1 ? 'Terminer' : 'Etape suivante'}</span>
                                </LoadingButton>

                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </div>
        </div>

    )
};
export default CallSupervisor;