import { useLoginMutation, useRegisterMutation } from '../apis/auth.api';
import { useDispatch } from 'react-redux';
import { setUserProfile, setUserToken } from '../slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { callApi } from '../apis/call.api';

export const useAuthService = () => {
  const navigate = useNavigate();
  const [loginMutation] = useLoginMutation();
  const [registerMutation] = useRegisterMutation();

  const dispatch = useDispatch();

  const logout = async () => {
    try {
      dispatch(callApi.util.resetApiState());
      dispatch(setUserToken(null));
      dispatch(setUserProfile(null));
    } catch (error) {
      throw error;
    }
  };

  const login = async (credentials : any) => {
    try {
      if(credentials.username != "etech") throw new Error("Le nom d'utilisateur n'existe pas.");
      if(credentials.password != 1234) throw new Error("Mot de passe incorrect.");

      // const result = await loginMutation(credentials).unwrap();
      const result = {
        access_token: "fake_token"
      }
      dispatch(setUserToken(result.access_token));
      navigate('/');
      return result;
    } catch (error) {
      throw error;
    }
  };

  const register = async (userData : any) => {
    try {
      const result = await registerMutation(userData).unwrap();
      navigate('/auth/login');
      return result;
    } catch (error) {
      throw error;
    }
  };

  return { login, register, logout};
};