import React from 'react';
import './App.css';
import Routes from './routes';

import { Fab } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthService } from './services/auth.service';
import { useSelector } from 'react-redux';

//  style={{backgroundColor:'#dddddd'}}
function App() {
  const token = useSelector((state: any) => state.auth.token);

  return (
    <div className="App">
      
      <Routes />
    </div>

  );
}

export default App;
