import Chatbot, { createChatBotMessage, createClientMessage } from "react-chatbot-kit";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import 'react-chatbot-kit/build/main.css'
import './ChatBot.css';
import { useCallback, useEffect, useState } from "react";
import Markdown from "react-markdown";
import ReactDomServer from 'react-dom/server';
import { Button, CircularProgress, Typography } from "@mui/material";
import rehypeExternalLinks from 'rehype-external-links'
import { useSelector } from "react-redux";
const Chat = ({ messages }: any) => {
    const render = (text: string): any => {
        return <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>{text}</Markdown>
    }
    const [key, setKey] = useState(0);
    const [config, setConfig] = useState<any | null>({
        initialMessages: [],
        botName: "Agent augmenté"
    });
    
    useEffect(()=>{
        setKey(key + 1);
    }, [messages]);
    
    // https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/advanced/configuration/
    return (
        <div style={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "center" }}>
            <div style={{ textAlign: 'left' }}>
                {config &&
                    <Chatbot
                        key={key}
                        config={config}
                        messageParser={MessageParser}
                        actionProvider={ActionProvider}
                        headerText='Agent augmenté'
                        placeholderText='Ecrire un message'

                        messageHistory={messages}
                    />
                }
            </div>
        </div>

    )
};
export default Chat;