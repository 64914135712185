import { createSlice } from '@reduxjs/toolkit'

const initialState = {  } 
// io(process.env.REACT_APP_SERVER_URL!)

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
  },
})
export default appSlice.reducer