import { useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Google from 'assets/images/icons/social-google.svg';
import { useAuthService } from '../services/auth.service';

// ============================|| FIREBASE - LOGIN ||============================ //

const LoginForm = ({ ...others }) => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);

  const { login } = useAuthService();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div style={{ minHeight: "100vh", width: "100%", display:"flex", alignItems:"center", justifyContent:"center" }}>
      <div style={{ width: 400 , textAlign:"left"}}>
        <p style={{ fontSize: 20, color: "#777777", marginBottom: 0 }}>Connexion sur le</p>
        <h1 style={{ fontSize: 40, color: "#015b97", marginTop: "0px", fontWeight: "500" }}>Superviseur d'appel</h1>
        <Formik
          initialValues={{
            username: '',
            password: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(255).required("Veuillez renseigner votre nom d'utilisateur."),
            password: Yup.string().max(255).required('Veuillez renseigner votre mot de passe.')
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setErrorMessage(null);
              const credentials = {
                "username": values["username"],
                "password": values["password"]
              }
              const loginResult = await login(credentials);
            } catch (err: any) {
              setErrorMessage(err.data ? err.data.detail : err.message);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit} {...others}>
              <FormControl fullWidth error={Boolean(touched.username && errors.username)} sx={{ ...theme.typography.body1 }}>
                <InputLabel htmlFor="outlined-adornment-username-login">Nom d'utilisateur</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-username-login"
                  type="text"
                  value={values.username}
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Nom d'utilisateur"
                  inputProps={{}}
                />
                {touched.username && errors.username && (
                  <FormHelperText error id="standard-weight-helper-text-username-login">
                    {errors.username}
                  </FormHelperText>
                )}
              </FormControl>
              <div style={{height: 20}}/>
              <FormControl fullWidth error={Boolean(touched.password && errors.password)} sx={{ ...theme.typography.body1 }}>
                <InputLabel htmlFor="outlined-adornment-password-login">Mot de passe</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-login"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mot de passe"
                  inputProps={{}}
                />
                {touched.password && errors.password && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box sx={{ mt: 2 }}>
                < Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained"  style={{ textTransform: 'none', backgroundColor:"#015b97" }}>
                  Se connecter
                </Button>
              </Box>
            </form>
          )}
        </Formik>
        {errorMessage && <Alert severity="error" style={{ marginTop: 10 }}>{errorMessage}</Alert>}
      </div>
    </div>
  );
};

export default LoginForm;
