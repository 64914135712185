import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';

const MessageParser = ({ children, actions } : any) => {
    // Should be current Conversation
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null);
    const handleSendMessage = async (message : string)=>{
        try {
            setErrorMessage(null);
          actions.handleMarkdownMessage(message);
        } catch (err: any) {
          setErrorMessage(err.data.message);
        }
      };
    const parse = (message : string) => {
        handleSendMessage(message);
    }; 
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions: {},
                });
            })}
        </div>
    );
};

export default MessageParser;