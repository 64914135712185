import { Box, Button, Chip, FormControl, FormLabel, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import StopWatch from './StopWatch';
import Chat from '../chatbot/Chat';
import Chatbot, { createChatBotMessage, createClientMessage } from "react-chatbot-kit";
import Markdown from "react-markdown";

import rehypeExternalLinks from 'rehype-external-links'
import Textarea from '@mui/joy/Textarea';
import { SnackbarProvider, useSnackbar } from 'notistack'
import { MuiFileInput } from 'mui-file-input';
const AudioManager = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const audioRef = useRef<HTMLAudioElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const models = [
        {
            type: "gemini",
            name: "gemini-1.5-flash-001",
            multimodal: true
        },
        {
            type: "gemini",
            name: "gemini-1.0-pro-002",
            multimodal: false
        },
        {
            type: "gemini",
            name: "gemini-1.5-pro-001",
            multimodal: false
        },
        {
            type: "palm",
            name: "chat-bison-32k",
            multimodal: false
        },
        {
            type: "palm",
            name: "chat-bison",
            multimodal: false
        }
    ];
    const languages = [
        {
            code: "fr-FR",
            name: "Français"
        },
        {
            code: "en-US",
            name: "Anglais"
        },
    ];
    const [selectedModelType, setSelectedModelType] = useState<string>("gemini");
    const [selectedModel, setSelectedModel] = useState<string>("gemini-1.0-pro-002");
    const [selectedLanguage, setSelectedLanguage] = useState<string>("fr-FR");
    const [context, setContext] = useState<string>(""); // "Tu es une personne très drôle. Réponds toujours avec un air amusant. N'hésite pas à faire des blagues."
    const [files, setFiles] = useState<any[]>([]);
    const [selectedFileToUpload, setSelectedFileToUpload] = React.useState<File | undefined>(undefined);
    const handleSelectedFileToUploadChange = (newValue: any) => {
        setSelectedFileToUpload(newValue)
        console.log(newValue);
    }
    

    useEffect(() => {
        if (!selectedFileToUpload) return;
        // console.log(selectedFileToUpload);
        selectedFileToUpload.arrayBuffer().then((data) => {
            let file = {
                name: selectedFileToUpload.name,
                content: selectedFileToUpload,
                mime_type: selectedFileToUpload.type
            };
            setFiles((prev) => [...prev, file]);
            setSelectedFileToUpload(undefined);
        })
    }, [selectedFileToUpload]);
    useEffect(() => {
        if (!files) return;
        console.log(files);

    }, [files]);
    const handleModelChange = (event: any) => {
        setSelectedModel(event.target.value);

    };
    useEffect(() => {
        if (!selectedModel) return
        let currentModels = models.filter((m) => m.name == selectedModel);
        if (currentModels.length == 0) throw new Error(`Incorect ${selectedModel}.`)
        setSelectedModelType(currentModels[0].type);
    }, [selectedModel]);

    const supportsMultimodal = useCallback(() => {
        if (!selectedModel) return false;
        let currentModels = models.filter((m) => m.name == selectedModel);
        if (currentModels.length == 0) throw new Error(`Incorect ${selectedModel}.`)
        return currentModels[0].multimodal;
    }, [selectedModel]);
    const handleLanguageChange = (event: any) => {
        setSelectedLanguage(event.target.value);
    };
    const handleContextChange = (event: any) => {
        console.log(event.target.value);
        setContext(event.target.value);
    };

    const render = (text: string): any => {
        return <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>{text}</Markdown>
    }
    const [messages, setMessages] = useState<any[]>([]);

    const [pc, setPC] = useState<RTCPeerConnection | null>(null);
    const [dc, setDC] = useState<RTCDataChannel | null>(null);
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    var dcInterval : NodeJS.Timer | null = null;

    var iceGatheringLog = {
        textContent: ""
    };
    var iceConnectionLog = {
        textContent: ""
    };
    var signalingLog = {
        textContent: ""
    };
    var dataChannelLog = {
        textContent: ""
    };

    // PEER CONNECTION
    const createPeerConnection = () => {
        var config = {
            sdpSemantics: 'unified-plan',
            iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }]
        };

        let pc = new RTCPeerConnection(config);
        // register some listeners to help debugging
        pc.addEventListener('icegatheringstatechange', () => {
            iceGatheringLog.textContent += ' -> ' + pc?.iceGatheringState;
        }, false);
        iceGatheringLog.textContent = pc.iceGatheringState;

        pc.addEventListener('iceconnectionstatechange', () => {
            iceConnectionLog.textContent += ' -> ' + pc?.iceConnectionState;
            if(pc?.iceConnectionState == "closed" || pc?.iceConnectionState === "failed") stopAudio();
        }, false);

        iceConnectionLog.textContent = pc.iceConnectionState;

        pc.addEventListener('signalingstatechange', () => {
            signalingLog.textContent += ' -> ' + pc?.signalingState;
        }, false);
        signalingLog.textContent = pc.signalingState;

        // connect audio / video
        pc.addEventListener('track', (evt) => {
            if (evt.track.kind == 'video' && videoRef.current)
                videoRef.current.srcObject = evt.streams[0];
            if (evt.track.kind == 'audio' && audioRef.current)
                audioRef.current.srcObject = evt.streams[0];
        });

        setPC(pc);
    }
    useEffect(() => console.log("Messages", messages), [messages]);

    const createDataChannel = () => {

        if (pc == null) throw new Error("RTC Peer connection is null");

        var time_start: number | null = null;

        const current_stamp = () => {
            if (time_start === null) {
                time_start = new Date().getTime();
                return 0;
            } else {
                return new Date().getTime() - time_start;
            }
        };
        let parameters: RTCDataChannelInit = {
            ordered: true,
            //maxPacketLifeTime:10000,
            //ordered:false,
            //maxRetransmits:0,
            //negotiated:true,
            //id:0
        };
        console.log("RTCDatachannel parameters", parameters);
        let dc = pc.createDataChannel('chat', parameters);
        dc.addEventListener('close', () => {
            dataChannelLog.textContent += '- close\n';
            if(dcInterval) clearInterval(dcInterval);
        });
        dc.addEventListener('open', () => {
            dataChannelLog.textContent += '- open\n';
            dc?.send("Hi from client");
            dcInterval = setInterval(() => {
                var message = 'ping ' + current_stamp();
                dataChannelLog.textContent += '> ' + message + '\n';
                if(dc.readyState == "open") dc.send(message);
            }, 1000);
        });
        dc.addEventListener('message', (evt) => {
            dataChannelLog.textContent += '< ' + evt.data + '\n';
            // console.log(evt.data );
            // if(typeof evt.data === "string") return;

            let data = null;
            try { data = JSON.parse(evt.data); }
            catch (e) { }
            if(data){
                if (data.type === "user_message") {
                    let user_message = data.value;
                    console.log("USER MESSSAGE", user_message);
                    setMessages((prevMessages) => [...prevMessages, createClientMessage(render(user_message), {})]);
                }
                else if (data.type === "bot_message") {
                    let bot_message = data.value;
                    console.log("BOT MESSSAGE", bot_message);
                    setMessages((prevMessages) => [...prevMessages, createChatBotMessage(render(bot_message), {})]);
                }
                else if (data.type === "error") {
                    let err_message = data.value;
                    console.error(err_message);
                    enqueueSnackbar(err_message, { variant: 'error' });
                    stopAudio();
                }
            }
        });

        // Acquire media and start negociation.
        mediaStream?.getTracks().forEach((track) => {
            pc?.addTrack(track, mediaStream!);
        });

        setDC(dc);
        //return negotiate();
    };
    function negotiate() {
        if (pc == null) throw new Error("RTC Peer connection is null");

        return pc.createOffer().then((offer) => {
            return pc?.setLocalDescription(offer);
        }).then(() => {
            // wait for ICE gathering to complete
            return new Promise((resolve) => {
                if (pc?.iceGatheringState === 'complete') {
                    resolve(true);
                } else {
                    const checkState = () => {
                        if (pc?.iceGatheringState === 'complete') {
                            pc?.removeEventListener('icegatheringstatechange', checkState);
                            resolve(true);
                        }
                    }
                    pc?.addEventListener('icegatheringstatechange', checkState);
                }
            });
        }).then(() => {
            var offer = pc?.localDescription;
            var codec;
            if (offer == null) throw new Error("Offer is null");

            const formData = new FormData();
            formData.append('sdp', offer.sdp);
            formData.append('type', offer.type);
            formData.append('config', new Blob([JSON.stringify({
                frame_rate: 48000,
                language: selectedLanguage,
                context: context,
                model: {
                    type: selectedModelType,
                    name: selectedModel,
                }
            })], { type: 'application/json' }));

            files.forEach((file, i) => {
                formData.append(`file-${i}`, new Blob([file.content], { type: file.mime_type }), file.name);
            });



            return fetch(`${process.env.REACT_APP_AIORTC_SERVER_URL}/offer`, {
                /*
                body: JSON.stringify({
                    sdp: offer.sdp,
                    type: offer.type,
                    config: {
                        frame_rate : 48000,
                        language : selectedLanguage,
                        context: context,
                        files: files,

                        model :{
                            type:selectedModelType,
                            name:selectedModel,
                        }
                    }
                }),
                headers: {
                    'Content-Type': 'application/json'
                },
                */
                body: formData,
                method: 'POST'
            });
        }).then((response) => {
            return response.json();
        }).then((answer) => {
            // document.getElementById('answer-sdp').textContent = answer.sdp;
            return pc?.setRemoteDescription(answer);
        }).catch((e) => {
            stopAudio();
            alert(e);

        });
    }

    function stopPeerConnection() {
        if (pc == null) throw new Error("RTC Peer connection is null");

        // close data channel
        if (dc) {
            dc.close();
        }

        // close transceivers
        if (pc.connectionState == "connected") {
            pc.getTransceivers().forEach((transceiver) => {
                if (transceiver.stop) {
                    transceiver.stop();
                }
            });
            // close local audio / video
            pc.getSenders().forEach((sender) => {
                sender?.track?.stop();
            });

            setTimeout(() => {pc?.close();}, 500);
        }


        // close peer connection
        setTimeout(() => {

            setMediaStream(null);
            setPC(null);
            setDC(null);

        }, 500);
    }


    const [audio, setAudio] = useState<string | null>(null);
    const state = useSelector((state) => state);
    const stopWatchRef = useRef<{
        handleStart: () => void;
        handleStop: () => void;
        handleReset: () => void;
    } | null>(null);

    const startCounter = () => {
        stopWatchRef.current?.handleStart();
    }
    const stopCounter = () => {
        stopWatchRef.current?.handleStop();
        stopWatchRef.current?.handleReset();

    }

    const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedInputDeviceId, setSelectedInputDeviceId] = useState<string>("");
    const [selectedOutputDeviceId, setSelectedOutputDeviceId] = useState<string>("");

    const [started, setStarted] = useState<boolean>(false);


    const audioBlobs = [];

    useEffect(() => {
        if (!mediaStream) return;



        createPeerConnection();
    }, [mediaStream]);
    useEffect(() => {
        if (!pc) return;
        createDataChannel();
    }, [pc]);
    useEffect(() => {
        if (!dc) return;
        negotiate();
    }, [dc]);
    useEffect(() => {
        const getAudioDevices = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true });   // To request permission
                const devices = await navigator.mediaDevices.enumerateDevices();
                console.log(devices);
                const inputDevices = devices.filter((device) => device.kind === 'audioinput');
                const outputDevices = devices.filter((device) => device.kind === 'audiooutput');
                setAudioInputDevices(inputDevices);
                setAudioOutputDevices(outputDevices);
            } catch (error) {
                console.error('Error getting audio devices:', error);
            }
        };

        getAudioDevices();
    }, []);

    const handleInputChange = (event: any) => {
        setSelectedInputDeviceId(event.target.value);
    };

    const handleOutputChange = (event: any) => {
        setSelectedOutputDeviceId(event.target.value);
    };

    const startAudio = async () => {
        if (!selectedInputDeviceId) {
            console.warn('Please select an audio input device');
            return;
        }
        try {
            // https://blog.addpipe.com/audio-constraints-getusermedia/
            let mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: selectedInputDeviceId,
                    //sampleRate: 48000,
                    // sampleSize: 2,
                    // channelCount: 1,
                    // autoGainControl: false,
                    // echoCancellation: false,
                    // noiseSuppression: true
                },
            });
            setMessages([]);
            setMediaStream(mediaStream);
            startCounter();
            setStarted(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopAudio = () => {
        stopPeerConnection();
        stopCounter();
        setStarted(false);
    };

    return (
        <div>

            <div id="media" style={{display:"none"}}>
                <h2>Media</h2>
                <audio ref={audioRef} id="audio" autoPlay={true}></audio>
                <video ref={videoRef} id="video" autoPlay={true} playsInline={true}></video>
            </div>
            <FormControl fullWidth>
                <FormLabel>Mise en contexte</FormLabel>
                <Textarea disabled={started} onChange={handleContextChange} value={context} minRows={10} variant="outlined" placeholder="Mettez ici le sujet ou le contexte dont vous voulez parler avec l'IA" />
            </FormControl>
            {
                supportsMultimodal() ?
                    <>
                        <Box sx={{ minWidth: 120 }} style={{ marginTop: 10 }}>
                            <MuiFileInput placeholder="hey" disabled={started} label="Fichier(s) de contexte" style={{ width: "100%" }} value={selectedFileToUpload} onChange={handleSelectedFileToUploadChange} />
                        </Box>
                        <div style={{ marginTop: 10, display: "flex", justifyContent: "start", gap: "10px", flexWrap: "wrap" }}>
                            {files.map((file, index) => (
                                <Chip key={index} label={file.name} onDelete={() => {

                                    setFiles(files.filter((f) => f != file));
                                }} />
                            ))}
                        </div>

                    </>
                    :
                    <></>
            }


            <FormControl fullWidth style={{ marginTop: 50 }}>
                <InputLabel id="language-label">Langue</InputLabel>
                <Select
                    labelId="language-label"
                    id="language-select"
                    value={selectedLanguage}
                    label="Langue"
                    onChange={handleLanguageChange}
                    disabled={started}
                >
                    {languages.map((language) => (
                        <MenuItem key={language.code} value={language.code}>{language.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="model-label">Modèle</InputLabel>
                <Select
                    labelId="model-label"
                    id="model-select"
                    value={selectedModel}
                    label="Langue"
                    onChange={handleModelChange}
                    disabled={started}
                >
                    {models.map((model) => (
                        <MenuItem key={model.name} value={model.name}>[{model.type}] {model.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="input-audio-label">Périphérique d'éntrée</InputLabel>
                <Select
                    labelId="input-audio-label"
                    id="input-audio"
                    value={selectedInputDeviceId}
                    label="Périphérique d'éntrée"
                    onChange={handleInputChange}
                    disabled={started}
                >
                    {audioInputDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div style={{ marginTop: 10 }}>
                <StopWatch ref={stopWatchRef} />
            </div>

            {/**
             <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel id="demo-simple-select-label">Périphérique de sortie</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedOutputDeviceId}
                    label="Périphérique de sortie"
                    onChange={handleOutputChange}
                >
                    {audioOutputDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>{device.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
             */}

            <div style={{ marginTop: 10 }}>
                <Button variant="outlined" style={{ marginRight: 5 }} onClick={startAudio} disabled={started}>Commencer à parler</Button>
                <Button variant="outlined" color='error' style={{ marginRight: 5 }} onClick={stopAudio} disabled={!started}>Arrêter de parler</Button>
            </div>


            {/* Optional: Integration with audio processing (replace with your logic) 
            <h2>Process Audio</h2>
            */}
            {/* ... Your code for processing audio from the mediaStream ... */}

            {/* Optional: Integration with audio playback (replace with your logic) 
            <h2>Play Audio</h2>
            <input type="file" accept="audio/*" onChange={(event) => {
                const audioFile = event?.target?.files ? event?.target?.files[0] : null;
                if (audioFile) {
                    const reader = new FileReader();
                    reader.onload = (e: any) => playAudio(e.target.result);
                    reader.readAsArrayBuffer(audioFile);
                }
            }} />
            */}
            <div style={{ marginTop: 30 }}>
                <Chat messages={messages} />
            </div>

        </div>
    );
};

export default AudioManager;