import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

const StopWatch = forwardRef((props, ref) => {
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const formatTime = (ms : number) => {
    const seconds = Math.floor(ms / 1000) % 60;
    const minutes = Math.floor(ms / (1000 * 60)) % 60;
    const hours = Math.floor(ms / (1000 * 60 * 60));

    const formattedString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    return formattedString;
  };

  useImperativeHandle(ref, () => {
    return {
      handleStart() {
        if (isRunning) return;
        setIsRunning(true);
        intervalRef.current = setInterval(() => {
          setElapsedTime((prevTime) => prevTime + 10); // Update every 10 milliseconds
        }, 10);
      },
      handleStop() {
        if (!isRunning) return;
        setIsRunning(false);
        clearInterval(intervalRef.current);
      },
      handleReset() {
        setIsRunning(false);
        setElapsedTime(0);
        clearInterval(intervalRef.current);
      }
    };
  }, []);
  return (
    <div className="stopwatch">
      <div className="display">{formatTime(elapsedTime)}</div>
    </div>
  );
});

export default StopWatch;