import { combineReducers } from 'redux';

// reducer import
import authReducer from '../slices/auth.slice';
import appReducer from '../slices/app.slice';

import { authApi } from '../apis/auth.api'; 
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';
import { callApi } from '../apis/call.api';
// ==============================|| COMBINE REDUCER ||============================== //

const persistConfig = {
  key: 'root',
  storage:sessionStorage,
}
const persistedReducer = persistReducer(persistConfig, authReducer)

const reducer = combineReducers({
  auth : persistedReducer,
  app: appReducer,
  [authApi.reducerPath]: authApi.reducer,
  [callApi.reducerPath]: callApi.reducer,
});

export default reducer;
