import { useEvaluateCallMutation, useGenerateDebriefMutation, useGetConversationCallMutation, useTranscribeCallMutation, useTranscribeFileMutation } from '../apis/call.api';

export interface CallTranscriptionModel{
  project: string,
  file_id: string
}
    
    
export interface CallEvaluationModel{
  conversation: Array<any>
  assessments: Array<any>
  matrix: Array<any>
}
    
    
export interface CallConversationModel{
  content: string
}
export interface CallDebriefModel{
  content: string
}
    

export const useCallService = () => {
  const [evaluateCallMutation, { isLoading: isEvaluateCallLoading }] = useEvaluateCallMutation();
  const [transcribeCallMutation, { isLoading: isTranscribeCallLoading }] = useTranscribeCallMutation();
  const [transcribeFileMutation, { isLoading: isTranscribeFileLoading }] = useTranscribeFileMutation();

  const [getConversationCallMutation, { isLoading: isGetConversationCallLoading }] = useGetConversationCallMutation();
  const [generateDebriefMutation, { isLoading: isGenerateDebriefLoading }] = useGenerateDebriefMutation();

  
  const evaluateCall = async (body : FormData) => {
    try {
      const result = await evaluateCallMutation(body).unwrap();     
      return result;
    } catch (error) {
      throw error;
    }
  };
  const transcribeCall = async (body : CallTranscriptionModel) => {
    try {
      const result = await transcribeCallMutation(body).unwrap();     
      return result;
    } catch (error) {
      throw error;
    }
  };
  const transcribeFile = async (body : FormData) => {
    try {
      const result = await transcribeFileMutation( body ).unwrap();     
      return result;
    } catch (error) {
      throw error;
    }
  };
  const getConversationCall = async (body : CallConversationModel) => {
    try {
      const result = await getConversationCallMutation(body).unwrap();     
      return result;
    } catch (error) {
      throw error;
    }
  };
  const generateDebrief = async (body : FormData) => {
    try {
      const result = await generateDebriefMutation(body).unwrap();     
      return result;
    } catch (error) {
      throw error;
    }
  };
  return { 
      evaluateCall ,
      transcribeCall,
      getConversationCall,
      transcribeFile,
      generateDebrief,
     isEvaluateCallLoading,
     isTranscribeCallLoading,
     isGetConversationCallLoading,
     isTranscribeFileLoading,
     isGenerateDebriefLoading
    };
};