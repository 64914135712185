import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
import LoginForm from '../auth/AuthLogin';
import AuthGuard from './AuthGuard';
import CallSupervisor from '../supervisor/CallSupervisor';
import CallAdvisor from '../advisor/CallAdvisor';
import PersistentDrawerLeft from '../layout/MainLayout';
// =================
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return (
    <Routes>
      <Route path="/auth">
        <Route path="login" element={<LoginForm />} />
        <Route path='' element={<Navigate to='/auth/login' />} />
      </Route>
      
      <Route element={<AuthGuard />}>
        <Route path="/" element={<PersistentDrawerLeft />}>
          <Route path="workspace">
            <Route path="supervisor" element={<CallSupervisor />} />
            <Route path="advisor" element={<CallAdvisor />} />

            <Route path='' element={<Navigate to='/workspace/advisor' />} />
          </Route>
          <Route path='' element={<Navigate to='/workspace' />} />
        </Route>
      </Route>
    </Routes>
  )
}
