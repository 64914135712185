import * as React from 'react';
import AudioManager from './AudioManager';
const CallAdvisor = () => {
    return (
        <div style={{ width: "100%", display: "flex", alignItems: "start", justifyContent: "center", paddingTop: "100px", paddingBottom: "100px" }}>
            <div style={{ textAlign: 'left', maxWidth: '1000px' }}>
                <h1 style={{ fontSize: 75, color: "#015b97", marginBottom: 15 }}>Agent augmenté</h1>
                <p style={{ fontSize: 40, color: "#777777", marginTop: "0px", fontWeight: "500" }}>Faites vous aider par l'IA.</p>
                <AudioManager />
            </div>
        </div>
    )
};
export default CallAdvisor;