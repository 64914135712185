import React from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links'
const ActionProvider = ({ createChatBotMessage, setState, children } : any) => {
    
    const handleMarkdownMessage = (content : string) => {
        const botMessage = createChatBotMessage(
            <Markdown rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>{content}</Markdown>
        );
        setState((prev : any) => ({
            ...prev,
            messages: [...prev.messages,botMessage],
        }));
    };
    
    
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleMarkdownMessage
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;